import React from 'react'

const FourOFour = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent:'center', marginTop: '100px'}}>
    <span className='text-white text-4xl'>FORBIDDEN</span>
  </div>
  )
}

export default FourOFour